import React from 'react';
import { Router, Redirect } from '@reach/router';
import Layout from '../../components/Layout';
import PrivateRoute from '../../components/PrivateRoute';
import Room from './room';
import Login from './login';

export default () => (
  <Layout>
    <Router basepath="/app">
      <Login path="login" />
      <Redirect noThrow from="/" path="/" to="room" />
      <PrivateRoute default path="room" component={Room} />
    </Router>
  </Layout>
);
